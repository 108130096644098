import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(/* webpackChunkName: "Gifts" */ 'ui/routes/gifts/routes')

const GiftsRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

GiftsRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

GiftsRouteHandler.displayName = 'GiftsRouteHandler'

export default GiftsRouteHandler

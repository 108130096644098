import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

import { LayoutConfigType, RoutePropsType } from 'types'
import MainLayout from 'ui/layouts/MainLayout'
import Fallback from './components/Fallback'

const RouteHandler = React.memo(
  ({ layoutConfig, routeProps, getComponent }) => {
    const Component = useMemo(() => loadable(getComponent), [getComponent])

    return (
      <MainLayout config={layoutConfig} location={routeProps.location}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component fallback={<Fallback />} {...routeProps} />
      </MainLayout>
    )
  }
)

RouteHandler.propTypes = {
  getComponent: PropTypes.func.isRequired,
  layoutConfig: PropTypes.shape(LayoutConfigType),
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

RouteHandler.displayName = 'RouteHandler'

export default RouteHandler

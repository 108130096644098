import React from 'react'
import PropTypes from 'prop-types'

import { LayoutConfigType, RoutePropsType } from 'types'

import RouteHandler from 'ui/common/RouteHandler'

const getComponent = () =>
  import(/* webpackChunkName: "Start" */ 'ui/routes/start/routes')

const StartRouteHandler = ({ layoutConfig, routeProps }) => (
  <RouteHandler
    getComponent={getComponent}
    layoutConfig={layoutConfig}
    routeProps={routeProps}
  />
)

StartRouteHandler.propTypes = {
  layoutConfig: PropTypes.shape(LayoutConfigType).isRequired,
  routeProps: PropTypes.shape(RoutePropsType).isRequired,
}

StartRouteHandler.displayName = 'StartRouteHandler'

export default StartRouteHandler
